import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from '../../assets/css/App.module.css'
import Background from '../Background'
import NavBar from '../NavBar'
import Sidebar from '../NavBar/Sidebar'


export default withRouter(function Layout(props) {
    const { routes, options, selectedOption, setSelectedOptions, children, expand, setExpand, search, setSearch } = props

    return <>
        <Background />
        <NavBar
            expand={expand}
            setExpand={setExpand}
            setSearch={setSearch}
            search={search}
            options={options}
            selectedOption={selectedOption}
            setSelectedOptions={setSelectedOptions}
        />
        <div id="content" className="scroll-container dark-scrollbar">
            <div className={styles['FullPage-container']}>
                <Sidebar
                    routes={routes}
                    expand={expand}
                    setExpand={setExpand}
                />
                {children}
            </div>
        </div>
    </>
})