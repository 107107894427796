import { ReactComponent as Eye } from '../../assets/img/icons/fonts/eye.svg'
import { ReactComponent as Animal } from '../../assets/img/icons/icon_animal.svg'
import { ReactComponent as Dashboard } from '../../assets/img/icons/icon_dashboard.svg'
import { ReactComponent as Employees } from '../../assets/img/icons/icon_employees.svg'
import { ReactComponent as Fields } from '../../assets/img/icons/icon_fields.svg'
import { ReactComponent as Assets } from '../../assets/img/icons/icon_machine-shop.svg'
import { ReactComponent as Map } from '../../assets/img/icons/icon_map.svg'
import { ReactComponent as Menu } from '../../assets/img/icons/icon_menu.svg'
import { ReactComponent as Search } from '../../assets/img/icons/icon_search.svg'
import { ReactComponent as Settings } from '../../assets/img/icons/icon_settings.svg'
import { ReactComponent as Shopping } from '../../assets/img/icons/icon_shopping.svg'
import { ReactComponent as Stats } from '../../assets/img/icons/icon_stats.svg'
import { ReactComponent as Workers } from '../../assets/img/icons/icon_workers.svg'
import { ReactComponent as pah } from '../../assets/img/plan-a-head-logo.svg'

export const MenuIcon = Menu
export const DashboardIcon = Dashboard
export const StatsIcon = Stats
export const ShoppingIcon = Shopping
export const AssetsIcon = Assets
export const MapIcon = Map
export const EmployeesIcon = Employees
export const WorkersIcon = Workers
export const AnimalIcon = Animal
export const FieldsIcon = Fields
export const SettingsIcon = Settings
export const SearchIcon = Search
export const PahIcon = pah
export const EyeIcon = Eye