import React from 'react'
import styles from '../../assets/css/App.module.css'

export default function Background() {
    return <div className="background-container">
        <div className={styles['FullPage-container']}><div>
            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <div className={styles['CrossFadeImage-crossFade']} style={{ position: 'absolute', animationDuration: '600ms', width: '100%', height: '100%', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}></div>
            </div>
            <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 2 }}></div>
        </div>
        </div>
    </div>
}
