import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../assets/css/App.module.css'
import { MenuIcon, SearchIcon, SettingsIcon } from '../Icons'

export default function NavBar(props) {
    const { expand, setExpand, search, setSearch } = props
    return <div className='nav-bar'>
        <div className={styles['NavBar-container']}>
            <div className={styles['NavBar-left'] + ' ' + styles['NavBar-side']}>
                <button className={styles['NavBarIconButton-button'] + ' ' + styles['Link-link'] + ' ' + styles['Link-default']} onClick={() => setExpand(!expand)}>
                    <MenuIcon className={styles['PahIcon-pahIcon'] + ' ' + styles['NavBarIconButton-icon']} />
                </button>
                <Link to={"/"} className={styles['NavBar-pahLogo'] + ' ' + styles['NavBarIconButton-button'] + ' ' + styles['IconButton-button'] + ' ' + styles['Link-link'] + ' ' + styles['Link-default']}>Plan-A-Head Agriculture Software</Link>
                <div className={styles['QuickSearch-container']}>
                    <div className={styles['QuickSearchInput-container']}>
                        <SearchIcon className={styles['PahIcon-pahIcon'] + ' ' + styles['QuickSearchInput-searchIcon']} />
                        <input data-qa-id="quickSearchInput" className={styles['QuickSearchInput-searchInput']} type="text" autoComplete="off" spellCheck="false" onChange={event => setSearch(event.target.value)} value={search} />
                    </div>
                </div>
            </div>
            <div className={styles['NavBar-right'] + ' ' + styles['NavBar-side']}>
                <Link id="id-4" aria-label="Settings" data-uid="id-5" to={"/"} className={styles['SettingsButton-button'] + ' ' + styles['NavBar-button'] + ' ' + styles['NavBarIconButton-button'] + ' ' + styles['Link-link'] + ' ' + styles['Link-default']}>
                    <SettingsIcon className={styles['PahIcon-pahIcon'] + ' ' + styles['NavBarIconButton-icon']} />
                </Link>
                {/* <button id="id-6" aria-label="Select Player" data-uid="id-8" className={styles['NavBar-button'] + ' ' + styles['NavBarIconButton-button'] + ' ' + styles['Link-link'] + ' ' + styles['Link-default']} type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560 560" id="pah-icon-remote-player-off-560" aria-hidden="true" className={styles['PahIcon-pahIcon'] + ' ' + styles['NavBarIconButton-icon']}><path d="M0 84v98c14.294 0 28.252 1.372 42 3.346V126h476v308H290.654c1.974 13.748 3.346 27.706 3.346 42h266V84H0m42 392c0-23.198-18.802-42-42-42v42h42m56 0h42c0-77.322-62.678-140-140-140v42c54.04 0 98 43.96 98 98m98 0h42c0-131.446-106.554-238-238-238v42c108.08 0 196 87.92 196 196"></path></svg>
                </button> */}
                {/* <button aria-haspopup="true" id="id-10" data-uid="id-12" className={styles['NavBar-accountButton'] + ' ' + styles['NavBarAccountButton-button'] + ' ' + styles['DisclosureArrowButton-disclosureArrowButton'] + ' ' + styles['Link-link'] + ' ' + styles['DisclosureArrowButton-medium'] + ' ' + styles['Link-link'] + ' ' + styles['Link-default']} type="button">
                    <div className={styles['NavBarAccountButton-avatarContainer']}>
                        <div className={styles['NavBarAccountButton-overlay']}></div>
                        <div className={styles['AvatarImg-avatar']}>
                            <div style={{ width: '30px', height: '30px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}></div>
                        </div>
                    </div>
                    <span className={styles['NavBarAccountButton-badge'] + ' ' + styles['Badge-badge'] + ' ' + styles['Badge-badge'] + ' ' + styles['Badge-primary'] + ' ' + styles['Badge-isHidden']} data-qa-id="badgeElement"></span>
                    <div className={styles['DisclosureArrowButton-disclosureArrow'] + ' ' + styles['DisclosureArrow-disclosureArrow'] + ' ' + styles['DisclosureArrowButton-down'] + ' ' + styles['DisclosureArrowButton-medium'] + ' ' + styles['DisclosureArrow-down'] + ' ' + styles['DisclosureArrow'] + ' ' + styles['DisclosureArrow-default'] + ' ' + styles['DisclosureArrow-medium']}></div>
                </button> */}
            </div>
        </div>
    </div>
}
