import React, { Suspense, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from '../routes/routes'
import NotFound from './Error'
import { AppRouteComponent } from './Helpers/RouteComponent'
import Layout from './Layout'


function App() {
  const getRoute = (routes, location) => {
    return routes.find(route => {
      return route.path === location
    })
  }
  let parent = getRoute(routes, window.location.pathname)
  const [selectedRoute, setSelectedRoute] = useState(parent ? parent : {})
  const [selectedRouteChild, setSelectedRouteChild] = useState(null)

  let optionsloc = JSON.parse(localStorage.getItem('Orgs'))
  let selectedOptionloc = JSON.parse(localStorage.getItem('S_Org'))

  const [options, setOptions] = useState(optionsloc !== null ? optionsloc : [])
  const [selectedOption, setSelectedOptions] = useState(selectedOptionloc !== null ? selectedOptionloc : options.length > 0 ? options[0] : null)
  const [expand, setExpand] = useState(false)
  const [search, setSearch] = useState('')

  if (options.length === 0 && window.location.pathname !== '/setup')
    window.location.replace('/setup')

  if (options.length > 0 && selectedOptionloc === null) {
    localStorage.setItem('OrgID', selectedOption.OrganisationID)
    localStorage.setItem('S_Org', JSON.stringify(selectedOption))
  }

  return <Layout
    routes={routes}
    expand={expand}
    search={search}
    options={options}
    setExpand={setExpand}
    setSearch={setSearch}
    setOptions={setOptions}
    selectedRoute={selectedRoute}
    selectedOption={selectedOption}
    setSelectedOptions={setSelectedOptions}
    selectedRouteChild={selectedRouteChild}
  >
    <Suspense fallback={<div className='app'>Loading...</div>}>
      <Switch>
        {routes.map((route, index) => {
          if (route.component)
            return <Route
              key={index}
              exact={route.exact}
              path={route.path}
              render={props =>
                <AppRouteComponent
                  route={route}
                  search={search}
                  options={options}
                  setOptions={setOptions}
                  selectedRoute={selectedRoute}
                  selectedOption={selectedOption}
                  setSelectedRoute={setSelectedRoute}
                  setSelectedOptions={setSelectedOptions}
                  selectedRouteChild={selectedRouteChild}
                  setSelectedRouteChild={setSelectedRouteChild}
                  {...props}
                />}
            />
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  </Layout>
}

export default App