import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './assets/css/index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

render(<Router><App /></Router>, document.getElementById('pah'))
serviceWorker.register()