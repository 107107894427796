import React from 'react'

export const AppRouteComponent = (props) => {
    props.setSelectedRoute(props.route)
    const MatchingComponent = props.route.component
    return <MatchingComponent {...props} />
}

export const RouteComponent = (props) => {
    props.route ? props.setSelectedRouteChild(props.route) : props.setSelectedRouteChild({})
    const MatchingComponent = props.route.component
    return <MatchingComponent {...props} />
}