import { lazy } from 'react'
import { AssetsIcon, DashboardIcon, FieldsIcon, MapIcon, SettingsIcon } from '../components/Icons'

const Home = lazy(() => import('./Home'))
const Setup = lazy(() => import('./Setup'))
// const Settings = lazy(() => import('./Settings'))

const Fields = lazy(() => import('./Fields'))
const FieldList = lazy(() => import('./Fields/List'))
const FieldNew = lazy(() => import('./Fields/New'))
const FieldEdit = lazy(() => import('./Fields/New'))

// const Assets = lazy(() => import('./Assets'))

const Maps = lazy(() => import('./Maps'))

// const Dashboard = lazy(() => import('./dashboard'))
// const DashboardOverview = lazy(() => import('./dashboard/overview'))
// // const DashboardHistory = lazy(() => const('./dashboard/history'))

// const { Callback } = lazy(() => import('../components/auth/callback'))
// const { Logout } = lazy(() => import('../components/auth/logout'))
// const { LogoutCallback } = lazy(() => import('../components/auth/logoutCallback'))
// const { SilentRenew } = lazy(() => import('../components/auth/silentRenew'))

// const Accounts = lazy(() => import('./accounts'))
// const SignUp = lazy(() => import('./accounts/SignUp'))
// const Profile = lazy(() => import('./accounts/Profile'))

// const Areas = lazy(() => import('./areas'))
// const AreaDashboard = lazy(() => import('./areas/Dashboard'))
// const AreaDetails = lazy(() => import('./areas/Details'))
// const AreaLoads = lazy(() => import('./areas/Loads'))
// const AreaOperations = lazy(() => import('./areas/Operations'))
// const AreaReports = lazy(() => import('./areas/Reports'))


// const FieldsDashboard = lazy(() => import('./fields/Dashboard'))
// const FieldsDetails = lazy(() => import('./fields/Details'))
// const FieldsLoads = lazy(() => import('./fields/Loads'))
// const FieldsOperations = lazy(() => import('./fields/Operations'))
// const FieldsReports = lazy(() => import('./fields/Reports'))

// const Vehicles = lazy(() => import('./vehicles'))
// const VehiclesDashboard = lazy(() => import('./vehicles/Dashboard'))
// const VehiclesActivities = lazy(() => import('./vehicles/Activities'))
// const VehiclesHistory = lazy(() => import('./vehicles/History'))
// const VehiclesReports = lazy(() => import('./vehicles/Reports'))
// const VehiclesReminders = lazy(() => import('./vehicles/Reminders'))

// const Employees = lazy(() => import('./employees'))
// const EmployeesList = lazy(() => import('./employees/List'))
// const EmployeesReports = lazy(() => import('./employees/Reports'))

// const Animals = lazy(() => import('./animals'))
// const AnimalsList = lazy(() => import('./animals/List'))
// const AnimalsDetails = lazy(() => import('./animals/Details'))
// const AnimalsActivities = lazy(() => import('./animals/Activities'))
// const AnimalsHistory = lazy(() => import('./animals/History'))
// const AnimalsReports = lazy(() => import('./animals/Reports'))
// const AnimalsVets = lazy(() => import('./animals/Vets'))

// const Stock = lazy(() => import('./stock'))
// const StockDashboard = lazy(() => import('./stock/Dashboard'))
// const StockHistory = lazy(() => import('./stock/History'))
// const StockUsage = lazy(() => import('./stock/Usage'))
// const StockReports = lazy(() => import('./stock/Reports'))

// const Settings = lazy(() => import('./settings'))
// const SettingEdit = lazy(() => import('./settings/Edit'))

export const routes = [
    {
        name: 'Home',
        path: '/',
        icon: DashboardIcon,
        exact: true,
        component: Home,
        routes: []
    },
    {
        name: 'Setup',
        path: '/setup',
        exact: true,
        exclude: true,
        icon: SettingsIcon,
        component: Setup,
        routes: []
    },
    // {
    //     name: 'Settings',
    //     path: '/settings',
    //     exact: true,
    //     exclude: true,
    //     icon: SettingsIcon,
    //     component: Settings,
    //     routes: []
    // },
    // {
    //     name: 'Activity',
    //     path: '/activity',
    //     icon: ShoppingIcon,
    //     exact: true,
    //     // component: Activity,
    //     routes: []
    // },
    {
        name: 'Fields',
        path: '/fields',
        icon: FieldsIcon,
        exact: false,
        component: Fields,
        routes: [
            {
                name: 'Fields',
                path: '/fields',
                exact: true,
                icon: FieldsIcon,
                component: FieldList
            },
            {
                name: 'New Field',
                path: '/fields/new',
                exact: true,
                icon: AssetsIcon,
                component: FieldNew
            },
            {
                name: 'Edit Field',
                path: '/fields/edit',
                exact: true,
                icon: AssetsIcon,
                component: FieldEdit
            }
        ]
    },
    // {
    //     name: 'Assets',
    //     path: '/assets',
    //     icon: AssetsIcon,
    //     exact: false,
    //     component: Assets,
    //     routes: []
    // },
    // {
    //     name: 'Employees',
    //     path: '/employees',
    //     icon: EmployeesIcon,
    //     exact: false,
    //     // component: Workers,
    //     routes: []
    // },
    {
        name: 'Maps',
        path: '/maps',
        icon: MapIcon,
        exact: true,
        component: Maps,
        routes: []
    }
]