import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/Sidebar.css'

export default function Sidebar(props) {
    const { routes, expand, setExpand } = props
    return <>
        <div className='area'></div>
        <nav className={expand ? 'main-menu expanded' : 'main-menu'}>
            <ul>
                {routes.map((link, index) =>
                    <Fragment key={index}>
                        {!link.hasOwnProperty('exclude') ?
                            <li>
                                <Link to={link.path} onClick={() => setExpand(false)}>
                                    {link.icon ? React.createElement(link.icon) : ''}
                                    <span className='nav-text'>
                                        {link.name}
                                    </span>
                                </Link>
                            </li>
                            : ''}
                    </Fragment>
                )}
            </ul>
            <ul className='logout'>
                <li>
                    <Link to={'/'}>
                        <i className='fa fa-power-off fa-2x'></i>
                        <span className='nav-text'>
                            Logout
                        </span>
                    </Link>
                </li>
            </ul>
        </nav>
    </>
}
